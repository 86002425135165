export default {
  setBasket(state, value) {
    state.basket = value
  },
  setBasketProducts(state, value) {
    state.basket.products = value
  },
  setBasketSequence(state, value) {
    state.basket.sequences = value;
  },
  cleanBasket(state) {
    state.basket.products = []
    state.basket.primers.items = []
    state.basket.sequences = null;
  },
  setPdDiscount(state, value) {
    state.pdDiscount = value
  },
  setSeqDiscount(state, value) {
    state.seqDiscount = value
  },

  setPrimerDiscount(state, value) {
    state.primerDiscount = value
  }

}
