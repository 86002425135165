export const AddClient = () => import('../../components/AddClient.vue' /* webpackChunkName: "components/add-client" */).then(c => wrapFunctional(c.default || c))
export const AddressForm = () => import('../../components/AddressForm.vue' /* webpackChunkName: "components/address-form" */).then(c => wrapFunctional(c.default || c))
export const BtnWithLoading = () => import('../../components/BtnWithLoading.vue' /* webpackChunkName: "components/btn-with-loading" */).then(c => wrapFunctional(c.default || c))
export const BufferSelectorCmp = () => import('../../components/BufferSelectorCmp.vue' /* webpackChunkName: "components/buffer-selector-cmp" */).then(c => wrapFunctional(c.default || c))
export const BuyProductArea = () => import('../../components/BuyProductArea.vue' /* webpackChunkName: "components/buy-product-area" */).then(c => wrapFunctional(c.default || c))
export const BuyProductArea4 = () => import('../../components/BuyProductArea4.vue' /* webpackChunkName: "components/buy-product-area4" */).then(c => wrapFunctional(c.default || c))
export const Degracalq = () => import('../../components/Degracalq.vue' /* webpackChunkName: "components/degracalq" */).then(c => wrapFunctional(c.default || c))
export const EditAddressForm = () => import('../../components/EditAddressForm.vue' /* webpackChunkName: "components/edit-address-form" */).then(c => wrapFunctional(c.default || c))
export const LinedTextarea = () => import('../../components/LinedTextarea.vue' /* webpackChunkName: "components/lined-textarea" */).then(c => wrapFunctional(c.default || c))
export const LoggedInNotice = () => import('../../components/LoggedInNotice.vue' /* webpackChunkName: "components/logged-in-notice" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const ModifiersButtons = () => import('../../components/ModifiersButtons.vue' /* webpackChunkName: "components/modifiers-buttons" */).then(c => wrapFunctional(c.default || c))
export const NewYearAlert = () => import('../../components/NewYearAlert.vue' /* webpackChunkName: "components/new-year-alert" */).then(c => wrapFunctional(c.default || c))
export const PagerSearchCmp = () => import('../../components/PagerSearchCmp.vue' /* webpackChunkName: "components/pager-search-cmp" */).then(c => wrapFunctional(c.default || c))
export const PasswordRecoveryForm = () => import('../../components/PasswordRecoveryForm.vue' /* webpackChunkName: "components/password-recovery-form" */).then(c => wrapFunctional(c.default || c))
export const PolymeraseStorageNotice = () => import('../../components/PolymeraseStorageNotice.vue' /* webpackChunkName: "components/polymerase-storage-notice" */).then(c => wrapFunctional(c.default || c))
export const PrimerEdit = () => import('../../components/PrimerEdit.vue' /* webpackChunkName: "components/primer-edit" */).then(c => wrapFunctional(c.default || c))
export const PrimerEditor = () => import('../../components/PrimerEditor.vue' /* webpackChunkName: "components/primer-editor" */).then(c => wrapFunctional(c.default || c))
export const PrimerView = () => import('../../components/PrimerView.vue' /* webpackChunkName: "components/primer-view" */).then(c => wrapFunctional(c.default || c))
export const ProductToBasket = () => import('../../components/ProductToBasket.vue' /* webpackChunkName: "components/product-to-basket" */).then(c => wrapFunctional(c.default || c))
export const ProductsGatewayNotice = () => import('../../components/ProductsGatewayNotice.vue' /* webpackChunkName: "components/products-gateway-notice" */).then(c => wrapFunctional(c.default || c))
export const ProductsPatentsNotice = () => import('../../components/ProductsPatentsNotice.vue' /* webpackChunkName: "components/products-patents-notice" */).then(c => wrapFunctional(c.default || c))
export const ProductsPriceNotice = () => import('../../components/ProductsPriceNotice.vue' /* webpackChunkName: "components/products-price-notice" */).then(c => wrapFunctional(c.default || c))
export const ProductsSpecialPriceNotice = () => import('../../components/ProductsSpecialPriceNotice.vue' /* webpackChunkName: "components/products-special-price-notice" */).then(c => wrapFunctional(c.default || c))
export const RCFRPMCalq = () => import('../../components/RCF-RPMCalq.vue' /* webpackChunkName: "components/r-c-f-r-p-m-calq" */).then(c => wrapFunctional(c.default || c))
export const RegistrationForm = () => import('../../components/RegistrationForm.vue' /* webpackChunkName: "components/registration-form" */).then(c => wrapFunctional(c.default || c))
export const ToBasketButtons = () => import('../../components/ToBasketButtons.vue' /* webpackChunkName: "components/to-basket-buttons" */).then(c => wrapFunctional(c.default || c))
export const UserClient = () => import('../../components/UserClient.vue' /* webpackChunkName: "components/user-client" */).then(c => wrapFunctional(c.default || c))
export const MenuBasketMenu = () => import('../../components/menu/BasketMenu.vue' /* webpackChunkName: "components/menu-basket-menu" */).then(c => wrapFunctional(c.default || c))
export const MenuLanguageSelector = () => import('../../components/menu/LanguageSelector.vue' /* webpackChunkName: "components/menu-language-selector" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftCiting = () => import('../../components/menu/LeftCiting.vue' /* webpackChunkName: "components/menu-left-citing" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftInstructions = () => import('../../components/menu/LeftInstructions.vue' /* webpackChunkName: "components/menu-left-instructions" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftMaterialsToDownload = () => import('../../components/menu/LeftMaterialsToDownload.vue' /* webpackChunkName: "components/menu-left-materials-to-download" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftMenu = () => import('../../components/menu/LeftMenu.vue' /* webpackChunkName: "components/menu-left-menu" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftOrderAndDelivery = () => import('../../components/menu/LeftOrderAndDelivery.vue' /* webpackChunkName: "components/menu-left-order-and-delivery" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftSubMenu = () => import('../../components/menu/LeftSubMenu.vue' /* webpackChunkName: "components/menu-left-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const MenuLeftTechnicalInfo = () => import('../../components/menu/LeftTechnicalInfo.vue' /* webpackChunkName: "components/menu-left-technical-info" */).then(c => wrapFunctional(c.default || c))
export const MenuProfile = () => import('../../components/menu/Profile.vue' /* webpackChunkName: "components/menu-profile" */).then(c => wrapFunctional(c.default || c))
export const MenuTopMenu = () => import('../../components/menu/TopMenu.vue' /* webpackChunkName: "components/menu-top-menu" */).then(c => wrapFunctional(c.default || c))
export const SearchTopSearch = () => import('../../components/search/TopSearch.vue' /* webpackChunkName: "components/search-top-search" */).then(c => wrapFunctional(c.default || c))
export const ModalsAuthModal = () => import('../../components/modals/AuthModal.vue' /* webpackChunkName: "components/modals-auth-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsClearBasketModal = () => import('../../components/modals/ClearBasketModal.vue' /* webpackChunkName: "components/modals-clear-basket-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsClearSynthesisFormModal = () => import('../../components/modals/ClearSynthesisFormModal.vue' /* webpackChunkName: "components/modals-clear-synthesis-form-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCopyOrderPromptModal = () => import('../../components/modals/CopyOrderPromptModal.vue' /* webpackChunkName: "components/modals-copy-order-prompt-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeleteAddressModal = () => import('../../components/modals/DeleteAddressModal.vue' /* webpackChunkName: "components/modals-delete-address-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeleteContactModal = () => import('../../components/modals/DeleteContactModal.vue' /* webpackChunkName: "components/modals-delete-contact-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeletePrimersModal = () => import('../../components/modals/DeletePrimersModal.vue' /* webpackChunkName: "components/modals-delete-primers-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeleteProductFromBasketModal = () => import('../../components/modals/DeleteProductFromBasketModal.vue' /* webpackChunkName: "components/modals-delete-product-from-basket-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeleteSequenceModal = () => import('../../components/modals/DeleteSequenceModal.vue' /* webpackChunkName: "components/modals-delete-sequence-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPrimersToBasketModal = () => import('../../components/modals/PrimersToBasketModal.vue' /* webpackChunkName: "components/modals-primers-to-basket-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsProductCountModal = () => import('../../components/modals/ProductCountModal.vue' /* webpackChunkName: "components/modals-product-count-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsProductListModal = () => import('../../components/modals/ProductListModal.vue' /* webpackChunkName: "components/modals-product-list-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSelectBufferModal = () => import('../../components/modals/SelectBufferModal.vue' /* webpackChunkName: "components/modals-select-buffer-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSequenceFormConfirm = () => import('../../components/modals/SequenceFormConfirm.vue' /* webpackChunkName: "components/modals-sequence-form-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalsViewPolicyModal = () => import('../../components/modals/ViewPolicyModal.vue' /* webpackChunkName: "components/modals-view-policy-modal" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksAddressBlock = () => import('../../components/page_blocks/AddressBlock.vue' /* webpackChunkName: "components/page-blocks-address-block" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksConfidentialityV1 = () => import('../../components/page_blocks/ConfidentialityV1.vue' /* webpackChunkName: "components/page-blocks-confidentiality-v1" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksConfidentialityV2 = () => import('../../components/page_blocks/ConfidentialityV2.vue' /* webpackChunkName: "components/page-blocks-confidentiality-v2" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksContactsBlock = () => import('../../components/page_blocks/ContactsBlock.vue' /* webpackChunkName: "components/page-blocks-contacts-block" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksModifiersList = () => import('../../components/page_blocks/ModifiersList.vue' /* webpackChunkName: "components/page-blocks-modifiers-list" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksModifiersPrice = () => import('../../components/page_blocks/ModifiersPrice.vue' /* webpackChunkName: "components/page-blocks-modifiers-price" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksPolicyText = () => import('../../components/page_blocks/PolicyText.vue' /* webpackChunkName: "components/page-blocks-policy-text" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksPolicyTextNew = () => import('../../components/page_blocks/PolicyTextNew.vue' /* webpackChunkName: "components/page-blocks-policy-text-new" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksPrimersTable = () => import('../../components/page_blocks/PrimersTable.vue' /* webpackChunkName: "components/page-blocks-primers-table" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksServicesChemistry = () => import('../../components/page_blocks/ServicesChemistry.vue' /* webpackChunkName: "components/page-blocks-services-chemistry" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksServicesGes = () => import('../../components/page_blocks/ServicesGes.vue' /* webpackChunkName: "components/page-blocks-services-ges" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksServicesNote = () => import('../../components/page_blocks/ServicesNote.vue' /* webpackChunkName: "components/page-blocks-services-note" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksServicesSynthesisAndSanger = () => import('../../components/page_blocks/ServicesSynthesisAndSanger.vue' /* webpackChunkName: "components/page-blocks-services-synthesis-and-sanger" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksSlider = () => import('../../components/page_blocks/Slider.vue' /* webpackChunkName: "components/page-blocks-slider" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksRowsContactsChemistryRow = () => import('../../components/page_blocks/rows/ContactsChemistryRow.vue' /* webpackChunkName: "components/page-blocks-rows-contacts-chemistry-row" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksRowsContactsGesRow = () => import('../../components/page_blocks/rows/ContactsGesRow.vue' /* webpackChunkName: "components/page-blocks-rows-contacts-ges-row" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksRowsContactsProductsRow = () => import('../../components/page_blocks/rows/ContactsProductsRow.vue' /* webpackChunkName: "components/page-blocks-rows-contacts-products-row" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksRowsContactsSangerRow = () => import('../../components/page_blocks/rows/ContactsSangerRow.vue' /* webpackChunkName: "components/page-blocks-rows-contacts-sanger-row" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksRowsContactsSynthesisRow = () => import('../../components/page_blocks/rows/ContactsSynthesisRow.vue' /* webpackChunkName: "components/page-blocks-rows-contacts-synthesis-row" */).then(c => wrapFunctional(c.default || c))
export const PageBlocksProductsPcrTaqAdditionals = () => import('../../components/page_blocks/products/pcr/taq/TaqAdditionals.vue' /* webpackChunkName: "components/page-blocks-products-pcr-taq-additionals" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
