import PrimerCollection from './primerCollection'
import _ from 'lodash'

export default function Basket(allModifiers, store) {
  this.store = store
  this.allModifiers = allModifiers
  this.cookieName = 'basketId'
  this.tabId = this.uuId()
  this.initBasket()
  addEventListener('storage', event => this.onStorageChange(event)) // Если не захваить this - в обработчике контекст теряется
}

Basket.prototype.onStorageChange = function(event) {
  if (event.key === 'syncHash') {
    const evt = JSON.parse(event.newValue)
    if (evt.tabId !== this.tabId) {
      this.store.dispatch('loadBasket', false)
    }
  } else if (event.key === 'basketId') {
    const evt = JSON.parse(event.newValue)
    if (evt.tabId === this.tabId) {
      return
    }
    this.initBasket()
    if (typeof this.onOrderDone !== 'undefined') {
      this.onOrderDone(this)
    }
  }
}

Basket.prototype.initBasket = function() {
  this.id = this.getCookieId()
  if (!this.id) {
    this.id = this.uuId()
    this.setCookieId(this.id)
    this.updateStorageBasketId(this.id)
  }
  this.primers = new PrimerCollection([], this.store)
  this.products = []
  this.loading = false
  this.updateStorageSync()
}

Basket.prototype.updateStorageBasketId = function(value) {
  const evt = { sync: value, tabId: this.tabId }
  localStorage.setItem('basketId', JSON.stringify(evt))
}

Basket.prototype.updateStorageSync = function() {
  const evt = { sync: this.uuId(), tabId: this.tabId }
  localStorage.setItem('syncHash', JSON.stringify(evt))
}

Basket.prototype.uuId = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

Basket.prototype.getCookieId = function() {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${this.cookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

Basket.prototype.setCookieId = function(value) {
  const date = new Date(new Date().getTime() + 2592000000) // + месяц
  document.cookie = `${this.cookieName}=${value}; path=/; expires=${date.toUTCString()}`
  return value
}

// Пересчитывает количество со скидкой (discount_count)
Basket.prototype.recalcProductsDiscount = function () {
  let groups = []
  // привязываем продукты к группам
  this.products.filter(p => !p.forDelete).forEach((product) => {
    if (product.groups) {
      product.discount_count = 0
      product.groups.forEach((groupInProduct) => {
        let fndGroup = _.find(groups, ['groupId', groupInProduct])
        if (!fndGroup) {
          fndGroup = {groupId: groupInProduct, products: []}
          groups.push(fndGroup)
        }
        if (product.discount_count * 1 === 0) {
          fndGroup.products.push(product)
        }
      })
    }
  })

  groups = groups.filter(group => _.sumBy(group.products, 'count') > 1)
  groups.forEach((group) => {
    const sortedProd = _.sortBy(group.products, ['price'])
    sortedProd[0].discount_count = sortedProd[0].count - 1
    _.tail(sortedProd).forEach((product) => {
      product.discount_count = product.count

      // Удаляем из других групп что бы скидка посчиталась только для одной группы
      groups.forEach((item) => {
        let i = item.products.indexOf(product)
          if (i > 0) {
            item.products.splice(i, 1)
          }
      })
    })
  })
}

