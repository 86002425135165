import PrimerCollection from '@/assets/primerCollection'
import Basket from '@/assets/basket'
import _ from 'lodash'

export default {
  async loadBasket(context, isNewBasket = true) {
    // todo: тут ошибка, потому что явно есть вызовы с isNewBasket === undefined, в сторе пусто, и при этом пытаемся в json.stringify засунуть basket.id
    // todo: new Basket() в IE не работает, и приводит к зацикливанию инициализации корзины
    const basket = isNewBasket
      ? new Basket(context.rootState.dictionaries.modifiers, context)
      : context.state.basket.basket;

    //Ошибка осталасть где была, но в логи не попадает;
    if (!basket) {
      return;
    }
    const response = await this.$myHelper.axiosWithMyEx('/basket/getBasket', JSON.stringify(basket.id), {headers: {'Content-type': 'application/json'}})
    basket.primers = new PrimerCollection(response.primers ? response.primers : [])
    basket.sequences = response.sequences ? response.sequences.seq : {elements:[], primers:[]};


    let discount =  context.rootState.user.user.discountInfo ?  (context.rootState.user.user.discountInfo.simplePrimerPercent || 0) * 1 : 0
    basket.primers.items.length > 0 && await context.dispatch('loadPrimersPrice', {primers : basket.primers.items, discountPercent: discount})
    response.products = response.products ? response.products : []
    response.products.forEach((item) => {
      item.forDelete = item.selected = false
    })

    basket.products = response.products
    basket.recalcProductsDiscount()

    basket.onUpdatePrimers && basket.onUpdatePrimers(basket.primers)
    basket.onUpdateProducts && basket.onUpdateProducts(basket.products)
    context.commit('setBasket', basket);
  },

  cleanBasket(context)
  {
    context.commit('cleanBasket');
  },



  async setBasketSequence(context, payLoad) {
    context.commit('setBasketSequence', payLoad);
  },

  async saveProducts(context) {
    context.state.basket.products.forEach((product) => {
      product.basket_id = context.state.basket.id
    })
    context.state.basket.recalcProductsDiscount()
    const response = await this.$myHelper.axiosWithMyEx('/product/saveProducts', context.state.basket.products)
    if (response) {
      response.forEach(item => item.forDelete = item.selected = false)
      context.state.basket.updateStorageSync()
      context.commit('setBasketProducts', response)
    }
  },

  async savePrimers(context, primersAndDiscount) {
    const primerDto = {
      primers: PrimerCollection.buildPrimersDto(primersAndDiscount.primers, primersAndDiscount.maxNumber),
      discountPercent: primersAndDiscount.discountPercent
    }
    primerDto.primers.forEach(item => item.basketId = context.state.basket.id)

    const response = await this.$myHelper.axiosWithMyEx('/primer/savePrimers', primerDto)

    if (response) {
      // почистим массив с праймерами от элементов с forDelete === true, потому что бэк уже должен был их удалить
      context.state.basket.primers.items = context.state.basket.primers.items
        .filter(i => i.forDelete === false)

      // пропишем для оставшихся праймеров новый id с бэка
      // в массиве в id c бэка тут видим есть (или нет?) в том чсиле пары id для элементов с forDelete
      response.primerIds.forEach((item) => {
        const fndPrimer = _.find(context.state.basket.primers.items, ['id', item.tempId * 1])

        if (fndPrimer) {
          fndPrimer.id = item.newId;
          fndPrimer.numberInOrder = item.numberInOrder;
        }
      })
      context.state.basket.updateStorageSync()
    }
  },

  async saveCleanBasket(context) {
    await this.$myHelper.axiosWithMyEx('/basket/cleanBasket', JSON.stringify(context.state.basket.id))
    context.commit('cleanBasket')
    context.state.basket.updateStorageSync()
  },

  async createOrder(context, orderInfo) {
    const primerDto = PrimerCollection.buildPrimersDto(_(context.state.basket.primers.items).filter([ 'editState', 'basket' ]).value())
    context.state.basket.products.forEach(item => item.basket_id = this.id)

    const dataForSend = {
      primers: primerDto,
      products: context.state.basket.products,
      order: orderInfo,
      basketId: context.state.basket.id
    }

    return await this.$myHelper.axiosWithMyEx('/basket/createOrder', dataForSend)
  },

  async loadPrimersPrice(context, primersAndClientId) {
    let primers = Array.isArray(primersAndClientId.primers) ? primersAndClientId.primers : [ primersAndClientId.primers ]
    primers.forEach(item => item.state = item.canCalcPrice() ? 'loading' : 'error')

    const itemsForCalcPrice = primers.filter(item => item.state !== 'error')
    const primersForSend = PrimerCollection.buildPrimersDto(itemsForCalcPrice)

    if (itemsForCalcPrice.length === 0) {
      return
    }

    let dataForSend = {
      primers: primersForSend,
      discountPercent: primersAndClientId.discountPercent
    }

    let response
    try {
      response = await this.$myHelper.axiosWithMyEx('/primer/CalcPrimersPrice', dataForSend)
    } catch (ex) {
      itemsForCalcPrice.forEach(item => item.state = 'error')
      return
    }
    itemsForCalcPrice.forEach((item, index) => {
      item.state = 'load'
      item.price = response[ index ].price
      item.discount_price = response[ index ].discountPrice
      item.priceCalcStrategy = response[index].priceCalcStrategy
    })
  }
}
